import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'src/index.scss';
import 'semantic-ui-css/semantic.min.css';
import App from 'src/components/App';
import store from 'src/redux/store';
import ThemeProvider from 'src/components/hoc/themeProvider';
import * as serviceWorker from 'src/serviceWorker';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
